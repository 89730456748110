<template>
  <div class="table-responsive mb-0">
    <table class="table table-bordernone table-striped">
      <thead>
        <tr>
          <th scope="col">İşlem</th>
          <th scope="col">Acente/Otel</th>
          <th scope="col">Kod</th>
          <th scope="col">Durum</th>
          <th scope="col">Tarih</th>
          <th scope="col">Otel</th>
          <th scope="col">Misafirler</th>
          <th scope="col" class="sabitKol">
            <div>Tur / Opsiyon</div>
          </th>
          <th scope="col">Rehber</th>
          <th scope="col">Şoför</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.Id">
          <td>
            <b-dropdown text="İşlem" variant="primary" class="m-2">
                    <b-dropdown-item :to="`/turRezervasyon/detay/${item.Id}`">Detay</b-dropdown-item>
                    <b-dropdown-item
                      :href="'https://card.capbook.net/home/tourImage/' + item.IntId + '?kod=' + item.Kod"
                      target="_blank">Rez. Kart İndir</b-dropdown-item>
                    <b-dropdown-item @click="kartGonder(item)">Rez. Kart Whatsapp Gönder</b-dropdown-item>
                  </b-dropdown>
          </td>
          <td>
            <b-badge variant="danger" v-if="item.AcenteTipi == 0">{{ item.AcenteAdi }}</b-badge>
            <b-badge variant="warning" v-if="item.AcenteTipi == 10">{{ item.AcenteAdi }}</b-badge>
          </td>
          <td>
            <b-badge variant="info">{{ item.Kod }}</b-badge>
          </td>
          <td>
            <RezervasyonDurum :durum="item.DurumStr" :durumInt="item.Durum" />
          </td>
          <td>
            <c-tarih-cell :kayitTarihi="item.Tarih" :rowKey="item.Id"></c-tarih-cell>
            <b-badge variant="primary">{{ item.Saat }}</b-badge>
          </td>
          <td>{{ item.OtelAdi }}</td>
          <td>
            <c-misafirler :misafirler="item.Misafirler"></c-misafirler>
            <b-badge variant="warning">{{ item.KisiSayisi }}</b-badge>
          </td>
          <td :class="item.TurRenk + ' sabitKol'">
            <div>
              {{ item.TurAdi }} / <small>{{ item.TurOpsiyonAdi }}</small>
            </div>
          </td>
          <td>
            <div v-if="item.RehberAdi !== null">
              <div class="customers">
                <ul class="mb-1">
                  <li class="d-inline-block">
                    <a href="#" :id="'rehber-tooltip-' + item.Id">
                      <img class="img-40 rounded-circle" :src="item.RehberResim" alt="" />
                    </a>
                  </li>
                </ul>
                {{ item.RehberAdi }}
              </div>
              <b-tooltip :target="'rehber-tooltip-' + item.Id" triggers="hover" placement="right">
                <img class="img-fluid" :src="item.RehberResim" alt="" />
              </b-tooltip>
            </div>
          </td>
          <td>
            <div v-if="item.SoforAdi !== null">
              <div class="customers">
                <ul class="mb-1">
                  <li class="d-inline-block">
                    <a href="#" :id="'arac-tooltip-' + item.Id" v-b-tooltip.hover>
                      <img class="img-40 rounded-circle" :src="item.AracResim" alt="" />
                    </a>
                  </li>
                </ul>
                {{ item.SoforAdi }}<br />
                {{ item.Plaka }}<br>
                <b-button v-if="item.isAracTakip" variant="info" @click="aracTakipAc(item.AracId, item.Id)"
                  size="xs">Lokasyon</b-button>
              </div>
              <b-tooltip :target="'arac-tooltip-' + item.Id" triggers="hover" placement="left">
                <img class="img-fluid" :src="item.AracResim" alt="" />
              </b-tooltip>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <AracTakipModal :aracId="aracTakipGosterId" :goster="aracTakipGoster" :turId="aracTakipGosterTurId"
      :regularId="aracTakipGosterRegularId" @kapatildi="aracTakipKapatildi()" />
  </div>
</template>
<script>
import RezervasyonDurum from "./c_turRezervasyon_durum";
import AracTakipModal from './c_arac_takip_goster';

export default {
  props: {
    tarih: null
  },
  data() {
    return {
      items: [],

      aracTakipGosterId: null,
      aracTakipGosterTurId: null,
      aracTakipGosterRegularId: null,
      aracTakipGoster: false
    };
  },
  components: {
    RezervasyonDurum,
    AracTakipModal
  },
  mounted() {
    this.listele();
  },
  watch: {
    tarih(newValue, oldValue) {
      this.listele();
    },
  },
  methods: {
    listele() {
      let vm = this;

      let filtre = {
        Tarih: this.$formatHelper.formatDateApi(this.tarih)
      }

      this.$connection.post(
        "/turRezervasyonlari/filtreForYonetim",
        filtre,
        "post",
        function (response) {
          vm.items = response;

          vm.$emit("onKayitSayisi", vm.items.length);
        }
      );
    },
    aracTakipAc(aracId, turId) {
      this.aracTakipGosterId = aracId;
      this.aracTakipGosterTurId = turId;
      this.aracTakipGosterRegularId = "";
      this.aracTakipGoster = true;
    },
    aracTakipKapatildi() {
      this.aracTakipGosterId = null;
      this.aracTakipGosterTurId = null;
      this.aracTakipGosterRegularId = null;
      this.aracTakipGoster = false;
    },
    kartGonder(rezervasyon){
      let vm = this;
      this.$connection.get("/turRezervasyonlari/KartGonder/" + rezervasyon.Id, function (response) {
        if(response.Success){
          vm.$alert.messageWithTitle("Rezervasyon Kartı Gönder", response.Message, "success");
        }else{
          vm.$alert.messageWithTitle("Rezervasyon Kartı Gönder", response.Message, "error");
        }
      });
    }
  },
};
</script>

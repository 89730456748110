<template>
  <div>
    <Breadcrumbs main="Araç Firma" :title="pageTitle" />

    <div class="container-fluid tur-rezervasyon-detay">
      <div class="row">
        <div class="col-md-12">
          <ul class="nav nav-tabs border-tab tabs-scoial" id="top-tab" role="tablist">
            <li class="nav-item" v-on:click="changetab('genel')">
              <a class="nav-link" :class="{ 'show active': showtab == 'genel' }" id="top-genel" data-toggle="tab"
                role="tab" aria-controls="genel" aria-selected="true">Genel <b-badge variant="info">{{ aracFirma.Adi
                  }}</b-badge></a>
            </li>
            <li class="nav-item" v-on:click="changetab('folyo')">
              <a class="nav-link" :class="{ 'show active': showtab == 'folyo' }" id="top-folyo" data-toggle="tab"
                role="tab" aria-controls="folyo" aria-selected="false">Folyo</a>
            </li>
            <li class="nav-item" v-on:click="changetab('kullanici')">
              <a class="nav-link" :class="{ 'show active': showtab == 'kullanici' }" id="top-kullanici"
                data-toggle="tab" role="tab" aria-controls="kullanici" aria-selected="false">Kullanıcılar</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="top-tabContent">
        <div class="tab-pane fade" :class="{ 'show active': showtab == 'genel' }" id="genel" role="tabpanel"
          aria-labelledby="genel">
          <div class="row">
            <div class="col-md-6">
              <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
                <h5 slot="header">Bilgiler</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Firma Adı</th>
                        <td>{{ aracFirma.Adi }}</td>
                      </tr>
                      <tr>
                        <th>Unvan</th>
                        <td>{{ aracFirma.Unvan }}</td>
                      </tr>
                      <tr>
                        <th>Yetkili Kişi</th>
                        <td>{{ aracFirma.YetkiliKisi }}</td>
                      </tr>
                      <tr>
                        <th>Vergi Dairesi</th>
                        <td>{{ aracFirma.VergiDairesi }}</td>
                      </tr>
                      <tr>
                        <th>Vergi Numarası</th>
                        <td>{{ aracFirma.VergiNo }}</td>
                      </tr>
                      <tr>
                        <th>Tur Ücreti</th>
                        <td>
                          <c-money-cell :fiyat="aracFirma.AlisUcreti" simge="₺"></c-money-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Toplam Alacak</th>
                        <td>
                          <c-money-cell :fiyat="aracFirma.ToplamAlacak" simge="₺"></c-money-cell>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>

              <b-card header-tag="div" header-bg-variant="info" class="card-absolute">
                <h5 slot="header">Kayıt Bilgileri</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Kayıt Tarihi</th>
                        <td>
                          <c-tarih-cell :kayitTarihi="aracFirma.KayitTarihi" :rowKey="aracFirma.Id"></c-tarih-cell>
                        </td>
                      </tr>
                      <tr>
                        <th>Güncelleme Tarihi</th>
                        <td>
                          <c-tarih-cell :kayitTarihi="aracFirma.GuncelTarih" :rowKey="aracFirma.Id"></c-tarih-cell>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <b-list-group class="mb-3">
                    <b-list-group-item v-b-modal.bilgiForm router-component-name="router-link">
                      <feather type="edit-3"></feather> Güncelle
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </div>

              <b-card header-tag="div" header-bg-variant="warning" class="card-absolute">
                <h5 slot="header">İletişim Bilgileri</h5>
                <b-card-body>
                  <table class="product-page-width">
                    <tbody>
                      <tr>
                        <th>Adres</th>
                        <td>{{ aracFirma.Adres }}</td>
                      </tr>
                      <tr>
                        <th>Telefon</th>
                        <td>{{ aracFirma.Telefon }}</td>
                      </tr>
                      <tr>
                        <th>Cep Telefonu</th>
                        <td>{{ aracFirma.GSM }}</td>
                      </tr>
                      <tr>
                        <th>Email</th>
                        <td>{{ aracFirma.Email }}</td>
                      </tr>
                      <tr>
                        <th>Operasyon Yetkilisi</th>
                        <td>{{ aracFirma.OperasyonYetkilisi }}</td>
                      </tr>
                      <tr>
                        <th>Operasyon Telefonu</th>
                        <td>{{ aracFirma.OperasyonTel }}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-card>
            </div>
          </div>

          <b-modal id="bilgiForm" centered title="Bilgirimi Güncelle" @ok="bilgiKaydetSubmit" ok-title="Güncelle"
            cancel-title="Kapat" class="theme-modal">
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_adi">Firma Adı</label>
                <b-form-input id="kayit_adi" v-model="kayitBilgi.Adi" :state="kayitbilgi_adi_state"></b-form-input>
                <b-form-invalid-feedback :state="kayitbilgi_adi_state">Firma adını giriniz.</b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_adi">Unvan</label>
                <b-form-input id="kayit_unvan" v-model="kayitBilgi.Unvan"
                  :state="kayitbilgi_unvan_state"></b-form-input>
                <b-form-invalid-feedback :state="kayitbilgi_unvan_state">Firma unvanını
                  giriniz.</b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_yetkilikisi">Yetkili Kişi</label>
                <b-form-input id="kayit_yetkilikisi" v-model="kayitBilgi.YetkiliKisi"
                  :state="kayitbilgi_yetkilikisi_state"></b-form-input>
                <b-form-invalid-feedback :state="kayitbilgi_yetkilikisi_state">Firma yetkilisini
                  giriniz.</b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_adres">Adres</label>
                <b-form-textarea id="kayit_adres" v-model="kayitBilgi.Adres"
                  :state="kayitbilgi_adres_state"></b-form-textarea>
                <b-form-invalid-feedback :state="kayitbilgi_adres_state">Firma adresini
                  giriniz.</b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_vergidairesi">Vergi Dairesi</label>
                <b-form-input id="kayit_vergidairesi" v-model="kayitBilgi.VergiDairesi"></b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_vergino">Vergi Numarası</label>
                <b-form-input id="kayit_vergino" v-model="kayitBilgi.VergiNo"
                  :state="kayitbilgi_vergino_state"></b-form-input>
                <b-form-invalid-feedback :state="kayitbilgi_vergino_state">Vergi ya da T.C. kimlik numarasını
                  giriniz.</b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_email">Email</label>
                <b-form-input id="kayit_email" v-model="kayitBilgi.Email"
                  :state="kayitbilgi_email_state"></b-form-input>
                <b-form-invalid-feedback :state="kayitbilgi_email_state">Geçerli bir email adresinizi
                  giriniz.</b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_telefon">Cep Telefonu</label>
                <b-form-input type="text" id="kayit_gsm" v-model="kayitBilgi.GSM" v-mask="'5## ### ## ##'"
                  placeholder="Cep Telefonu" :state="kayitbilgi_gsm_state"></b-form-input>
                <b-form-invalid-feedback :state="kayitbilgi_gsm_state">Cep telefonunu giriniz</b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_telefon">Telefon</label>
                <b-form-input id="kayit_telefon" v-model="kayitBilgi.Telefon"></b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_operasyonyetkilisi">Operasyon Yetkilisi</label>
                <b-form-input id="kayit_operasyonyetkilisi" v-model="kayitBilgi.OperasyonYetkilisi"></b-form-input>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="kayit_operasyontel">Operasyon Telefonu</label>
                <b-form-input id="kayit_operasyontel" v-model="kayitBilgi.OperasyonTel"></b-form-input>
              </div>
            </div>
          </b-modal>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'folyo' }" id="folyo" role="tabpanel"
          aria-labelledby="folyo">
          <div class="row">
            <div class="col-md-12">
              <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
                <h5 slot="header">Folyo</h5>
                <b-card-body class="p-1">
                  <AracFirmaFolyo :aracFirma="this.aracFirma" v-if="isFolyoLoad" :noEdit="true">
                  </AracFirmaFolyo>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'kullanici' }" id="kullanici" role="tabpanel"
          aria-labelledby="kullanici">
          <div class="row">
            <div class="col-md-12">
              <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
                <h5 slot="header">Kullanıcılar</h5>
                <b-card-body class="p-1">
                  <AracFirmaKullanici :aracFirmaId="aracFirma.Id" v-if="isKullaniciLoad" :kullanicilar="kullanicilar"
                    @guncellendi="kullaniciGuncellendi"></AracFirmaKullanici>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AracFirmaFolyo from "@/components/c_aracFirma_folyo.vue";
import AracFirmaKullanici from "@/components/c_aracFirma_kullanici";

export default {
  data() {
    return {
      pageTitle: "Araç Firma Profili",

      showtab: "genel",

      aracFirma: {},

      isFolyoLoad: false,

      kayitBilgi: {
        Adi: "",
        Unvan: "",
        YetkiliKisi: "",
        Adres: "",
        VergiDairesi: "",
        VergiNo: "",
        Telefon: "",
        GSM: "",
        Email: "",
        OperasyonYetkilisi: "",
        OperasyonTel: "",
      },

      kullanicilar: [],
      isKullaniciLoad: false,
    };
  },
  components: {
    AracFirmaFolyo,
    AracFirmaKullanici,
  },
  computed: {
    kayitbilgi_adi_state() {
      if (this.kayitBilgi.Adi == null || this.kayitBilgi.Adi == "")
        return false;
      else return true;
    },
    kayitbilgi_unvan_state() {
      if (this.kayitBilgi.Unvan == null || this.kayitBilgi.Unvan == "")
        return false;
      else return true;
    },
    kayitbilgi_yetkilikisi_state() {
      if (
        this.kayitBilgi.YetkiliKisi == null ||
        this.kayitBilgi.YetkiliKisi == ""
      )
        return false;
      else return true;
    },
    kayitbilgi_adres_state() {
      if (this.kayitBilgi.Adres == null || this.kayitBilgi.Adres == "")
        return false;
      else return true;
    },
    kayitbilgi_vergino_state() {
      if (
        this.kayitBilgi.VergiNo == null ||
        this.kayitBilgi.VergiNo.length < 10 ||
        this.kayitBilgi.VergiNo.length > 11
      )
        return false;
      else return true;
    },
    kayitbilgi_email_state() {
      if (!this.$validationHelper.validEmail(this.kayitBilgi.Email))
        return false;
      else return true;
    },
    kayitbilgi_gsm_state() {
      if (!this.$validationHelper.validGsm(this.kayitBilgi.GSM)) return false;
      else return true;
    },
  },
  mounted() {
    this.onDetay();
  },
  methods: {
    changetab(tab) {
      this.showtab = tab;

      switch (tab) {
        case "folyo":
          if (this.isFolyoLoad === false) this.folyoList();
          break;

        case "kullanici":
          if (this.isKullaniciLoad === false) this.kullaniciList();
          break;

        default:
          break;
      }
    },
    onDetay() {
      let vm = this;

      this.$connection.get("/aracFirmalari/profil", function (response) {
        if (response.Success) {
          vm.aracFirma = response.Data;

          vm.kayitBilgi = {
            Adi: vm.aracFirma.Adi,
            Unvan: vm.aracFirma.Unvan,
            YetkiliKisi: vm.aracFirma.YetkiliKisi,
            Adres: vm.aracFirma.Adres,
            VergiDairesi: vm.aracFirma.VergiDairesi,
            VergiNo: vm.aracFirma.VergiNo,
            Telefon: vm.aracFirma.Telefon,
            GSM: vm.aracFirma.GSM,
            Email: vm.aracFirma.Email,
            OperasyonYetkilisi: vm.aracFirma.OperasyonYetkilisi,
            OperasyonTel: vm.aracFirma.OperasyonTel,
          };
        } else {
          vm.$alert.messageWithTitle("Araç Firması", response.Message, "error");
        }
      });
    },
    folyoList() {
      this.isFolyoLoad = true;
    },
    bilgiKaydetSubmit(target) {
      target.preventDefault();

      let isValid =
        this.kayitbilgi_adi_state &&
        this.kayitbilgi_unvan_state &&
        this.kayitbilgi_yetkilikisi_state &&
        this.kayitbilgi_adres_state &&
        this.kayitbilgi_vergino_state &&
        this.kayitbilgi_email_state &&
        this.kayitbilgi_gsm_state;

      if (isValid) {
        let vm = this;

        vm.$connection.post(
          "/aracFirmalari/bilgi/" + this.aracFirma.Id,
          vm.kayitBilgi,
          "put",
          function (response) {
            if (response.Success) {
              vm.$alert.messageWithFunction(
                "Bilgi Güncelle",
                response.Message,
                "success",
                function () {
                  vm.$bvModal.hide("bilgiForm");
                  vm.onDetay();
                }
              );
            }
          },
          function (error) {
            vm.$alert.messageWithTitle(
              "Bilgi Güncelle",
              error.Message,
              "error"
            );
          }
        );
      }
    },
    kullaniciList() {
      let vm = this;

      this.$connection.get(
        "/aracFirmalari/" + this.aracFirma.Id + "/kullanicilar",
        function (response) {
          vm.kullanicilar = response;
          vm.isKullaniciLoad = true;
        }
      );
    },
    kullaniciGuncellendi(payload) {
      this.kullanicilar = payload;
    },
  },
};
</script>
